import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 960px;
  margin: 96px auto;
`

const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-transform: capitalize;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 24px;
    line-height: 36px;
  }
`

const Description = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 72px;
  text-align: center;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-bottom: 24px;
  }
`

const Img = styled.img`
  width: 100%;
  max-width: 450px;
  margin: auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: 240px;
  }
`

type Props = {
  title: string
  description: string
  img?: any
}
export const Message: React.VFC<Props> = ({title, description, img=null}) => {
  return (
    <Wrapper>
      {
        img &&
          <ImageWrapper>
            <Img src={img} alt={title} />
          </ImageWrapper>
      }
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  )
}
