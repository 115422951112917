import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Wrapper = styled.div`
  width: 100vw;
  width: calc(var(--vw, 1vw) * 100);
  margin: auto calc(50% - (calc(var(--vw, 1vw) * 100)) / 2);
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  & > .gatsby-image-wrapper {
    aspect-ratio: 2.3/1;
  }
`

type Props = {
  alt: string
  img: any
}
export const MainVisual: React.VFC<Props> = (props) => {

  return (
    <Wrapper>
      <Img fluid={props.img} alt={props.alt} />
    </Wrapper>
  )
}