import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-transform: capitalize;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
`
const EngTitle = styled.h3`
  margin: 0;
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 24px;
    line-height: 32px;
  }
`
const Caption = styled.span`
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #61B1C1;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 12px;
    line-height: 24px;
  }
`

type Props = {
  title: string
  caption?: string
  eng?: boolean
}
export const TitleAndCaption: React.VFC<Props> = ({title, caption = null, eng = false}) => {
  return (
    <Wrapper>
      {
        eng ? <EngTitle>{title}</EngTitle>: <Title>{title}</Title>
      }
      {
        caption && <Caption>{caption}</Caption>
      }
    </Wrapper>
  )
}
