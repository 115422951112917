import React from "react"
import { ButtonLink } from "../common/ButtonLink";

type Props = {
  title?: string
}
export const RequestJobButtonLink: React.VFC<Props> = ({title="お見積もり・<br class=\"br br__sp\" />お仕事のご依頼はこちら"}) => {
  return (
    <ButtonLink title={title} url="/contact/service" />
  )
}
