import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { SpWrapper } from "../../common/SpWrapper"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  width: 100vw;
  width: calc(var(--vw, 1vw) * 100);
  margin: auto calc(50% - (calc(var(--vw, 1vw) * 100)) / 2);
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 160px;
  width: 100%;
  max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px;
  margin: 160px auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 80px;
    margin: 80px auto;
  }
`

type Props = {
  children: any
}
export const WhiteContainer: React.VFC<Props> = ({children}) => {
  return (
    <Wrapper>
      <SpWrapper>
        <Container>
          {children}
        </Container>
      </SpWrapper>
    </Wrapper>
  )
}
