import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"

import Icon1 from "../../../../images/service/support_field_client_server.svg"
import Icon2 from "../../../../images/service/support_field_web.svg"
import Icon3 from "../../../../images/service/support_field_mobile.svg"
import Icon4 from "../../../../images/service/support_field_cloud.svg"
import Icon5 from "../../../../images/service/support_field_ai.svg"
import Icon6 from "../../../../images/service/support_field_erp.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
  }
`
const Contents = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    grid-template-columns: 1fr;
  }
`
const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background: #FFFFFF;
`
const Image = styled.img`
  width: 80px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 64px;
  }
`
const Title = styled.h4`
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #222222;
  margin: 0;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`
const Icons = [
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
]
type Props = {
  title: string
  caption: string
  contents: Array<any>
}
export const Field: React.VFC<Props> = ({title, caption, contents}) => {
  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        {
          contents.map((content, i) => {
            return (
              <Content>
                <Image src={Icons[i]} alt={content.title} />
                <Title>{content.title}</Title>
              </Content>
            )
          })
        }
      </Contents>
    </Wrapper>
  )
}
