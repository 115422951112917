import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { SpWrapper } from "../../common/SpWrapper"

const Wrapper = styled.div<{isBorderBottom?: boolean}>`
  display: flex;
  flex-direction: column;
  background: #EFEFEF;
  width: 100vw;
  width: calc(var(--vw, 1vw) * 100);
  margin: auto calc(50% - (calc(var(--vw, 1vw) * 100)) / 2);
  border-bottom: ${props => props.isBorderBottom ? "1px solid #222222" : "none"};
`
const Container = styled.div<{isContinuousGray?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 160px;
  width: 100%;
  max-width: ${props => props.isContinuousGray ? RESPONSIVE_STYLES.MAX_WIDTH : "960"}px;
  margin: auto;
  padding-block: 160px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 80px;
    padding-block: 64px;
  }
`

type Props = {
  children: any
  isContinuousGray?: boolean
  isBorderBottom?: boolean
}
export const GrayContainer: React.VFC<Props> = ({children, isContinuousGray, isBorderBottom}) => {
  return (
    <Wrapper isBorderBottom={isBorderBottom}>
      <SpWrapper>
        <Container isContinuousGray={isContinuousGray}>
          {children}
        </Container>
      </SpWrapper>
    </Wrapper>
  )
}
