import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"
import { RequestJobButtonLink } from "../../common/RequestJobButtonLink";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
  }
`
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: 960px;
  padding: 48px 96px;
  box-sizing: border-box;
  background: #FFFFFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 32px;
  }
`

const Description = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #222222;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const Name = styled.h4`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  tr {
    &:nth-child(2n-1) {
      background-color: #E9E9E9;
    }
  }
  td {
    padding: 8px 16px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.05em;
    font-style: normal;
    color: #222222;
    border: 1px solid #AAAAAA;
  }
`

type Props = {
  title: string
  caption: string
  description: string
  contents: Array<any>
  remarks: Array<string>
}
export const Service: React.VFC<Props> = ({title, caption, description, contents, remarks}) => {
  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        <Description dangerouslySetInnerHTML={{__html: description}} />
        {
          contents.map((content, i) => {
            return (
              <ContentWrapper key={i}>
                <Name>{content.name}</Name>
                <Description dangerouslySetInnerHTML={{__html: content.detail}} />
                <Table>
                {
                  content.rows.map((row, j) => {
                    return (
                      <tr key={j}>
                        <td>{row.title}</td>
                        <td>{row.description}</td>
                      </tr>
                    )
                  })
                }
                </Table>
              </ContentWrapper>
            )
          })
        }
        <RequestJobButtonLink />
      </Contents>
    </Wrapper>
  )
}
