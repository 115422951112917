import React from "react"
import Layout from "../../../template/Layout"
import Head from "../../../head";
import {
  Props as BreadcrumbsData,
} from "../../common/Breadcrumbs"
import { ServiceContainer } from "../common/ServiceContainer";
import { SectionTitle } from "../../common/SectionTitle";

import { ServiceContentContainer } from "../common/ServiceContentContainer";
import { MainVisual } from "../common/MainVisual";
import { Message } from "../common/Message";
import { GrayContainer } from "../common/GrayContainer";
import { WhiteContainer } from "../common/WhiteContainer";
import { Partner } from "./common/partner";
import { Strength } from "./common/Strength";
import { Field } from "./common/Field";
import { Service } from "./common/Service";
import { ogpUrl } from "../../../utlis/ImageUtil"

/**
 * Component
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
  icon: any
  imgMain: any
  support: any
}

/**
 * Component
 */
export const ServiceSupport: React.VFC<Props> = (props) => {

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title={props.support.ogp.title}
        description={props.support.ogp.description}
        img={ogpUrl(process.env.NODE_ENV, props.imgMain)}
      />
      <ServiceContainer paddingBottom={0}>
        <SectionTitle title={props.support.title.en} subTitle={props.support.title.ja} icon={props.icon} />
        <ServiceContentContainer>
          <MainVisual img={props.imgMain} alt={props.support.title.ja} />
          <Message title={props.support.message.title} description={props.support.message.description} />
          <GrayContainer>
            <Strength
              title={props.support.strength.title.ja}
              caption={props.support.strength.title.en}
              message={props.support.strength.message}
            />
            <Field
              title={props.support.field.title.ja}
              caption={props.support.field.title.en}
              contents={props.support.field.contents}
            />
            <Service
              title={props.support.service.title.ja}
              caption={props.support.service.title.en}
              description={props.support.service.description}
              contents={props.support.service.contents}
              remarks={props.support.service.remarks}
            />
          </GrayContainer>
          {
            // 今は使わない
            //  <PickUpArticleList articles={props.articles} url={props.support.pickup.url} />
          }
          <WhiteContainer>
            <Partner
              title={props.support.partner.title.ja}
              caption={props.support.partner.title.en}
              description={props.support.partner.description}
              categories={props.support.partner.categories}
            />
          </WhiteContainer>
          {/* <GrayContainer>
            <Faq
              title={props.support.faq.title.ja}
              contents={props.support.faq.contents}
              url={'/question/support'}
            />
          </GrayContainer> */}
        </ServiceContentContainer>
      </ServiceContainer>
    </Layout>
  )
}
