import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"
import { RequestPartnerButtonLink } from "../../common/RequestPartnerButtonLink"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 960px;
  margin: auto;
  gap: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
  }
`
const Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 96px;

  width: 100%;
  box-sizing: border-box;

  background: #FFFFFF;
  border-radius: 20px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 48px;
  }
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
  }
`
const Description = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`
const Categories = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    flex-direction: column;
  }
`
const Category = styled.span`
  display: flex;
  flex: 1;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #222222;
  background: #EFEFEF;

  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    aspect-ratio: unset;
    padding: 16px;
  }
`

type Props = {
  title: string
  caption: string
  description: string
  categories: Array<string>
}
export const Partner: React.VFC<Props> = ({title, caption, description, categories}) => {

  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        <Content>
          <Header>
            <Description>{description}</Description>
            <Categories>
              {
                categories.map((category, i) => {
                  return (
                    <Category key={i}>{category}</Category>
                  )
                })
              }
            </Categories>
          </Header>
          <RequestPartnerButtonLink />
        </Content>
      </Contents>
    </Wrapper>
  )
}
