import React from "react"
import { graphql } from "gatsby"
import { ServiceSupport } from "../../../components/service/support"
import Icon from "../../../images/service/icon_service_support.svg"

export default function ServiceSupportPage({ data }) {
  const {
    imgMain,
    site: { siteMetadata: { services } },
  } = data;

  const breadcrumbsData: { text: string; path: string }[] = [
    { text: services.top.title.en, path: services.top.path },
    { text: services.support.title.ja, path: services.support.path },
  ]

  return (
    <ServiceSupport
      breadcrumbsData={breadcrumbsData}
      icon={Icon}
      imgMain={imgMain.childImageSharp.fluid}
      support={services.support}
    />
  )
}

export const query = graphql`
  query ServiceSupportQuery {
    site {
      siteMetadata {
        services {
          top {
            path
            title {
              ja
              en
            }
          }
          support {
            path
            ogp {
              title
              description
            }
            title {
              ja
              en
            }
            description
            message {
              title
              description
            }
            strength {
              title {
                ja
                en
              }
              message
            }
            field {
              title {
                ja
                en
              }
              contents {
                title
              }
            }
            skill {
              title {
                ja
                en
              }
              names
            }
            service {
              title {
                ja
                en
              }
              description
              contents {
                name
                detail
                rows {
                  title
                  description
                }
              }
              remarks
            }
            partner {
              title {
                ja
                en
              }
              description
              categories
            }
            pickup {
              url
            }
            faq {
              title {
                ja
              }
              contents {
                question
                answer
              }
            }
          }
        }
      }
    }
    imgMain: file(relativePath: { eq: "images/service/support_thumbnail.JPG"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
