import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
  }
`

const Message = styled.p`
  margin: 0;
  padding: 48px 96px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #222222;
  background-color: #FFFFFF;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 32px;
  }
`

type Props = {
  title: string
  caption: string
  message: string
}

export const Strength: React.VFC<Props> = ({title, caption, message}) => {
  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Message>{message}</Message>
    </Wrapper>
  )
}
