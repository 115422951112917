import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { RoundLinkButton } from "../../common/RoundLinkButton"

const Wrapper = styled.div`
  width: 100%;
  max-width: 440px;
  margin: auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: 335px;
  }
`

type Props = {
  title: string
  url: string
}
export const ButtonLink: React.VFC<Props> = ({title, url}) => {
  return (
    <Wrapper>
      <RoundLinkButton title={title} url={url} />
    </Wrapper>
  )
}
